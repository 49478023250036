import { Component } from '@angular/core';
import { version } from 'environments/version';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BIZPAY';

  constructor(){
    console.log(environment.type +" "+ version);
    let sle = localStorage.getItem("form")
    // alert(JSON.stringify(sle))
  }
}
