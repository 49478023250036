//#region Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//#endregion Imports

//#region internal Imports
import { NgxSpinnerModule } from 'ngx-spinner';
import { LayoutComponents } from './layout/_module';
import { PrimeNgModules } from '../root/primeng.module';
import { AdminSideBarComponent } from './layout/admin-side-bar/admin-side-bar.component';
import { CustomPipes } from './pipe/_module';
import { SharedComponents } from './shared-component/_module';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { PlanOfferedComponent } from './layout/plan-offered/plan-offered.component';
import { FreeTrialReminderComponent } from './shared-component/free-trial-reminder/free-trial-reminder.component';
import { ManagePinComponent } from './shared-component/manage-pin/manage-pin.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ValidatePinComponent } from './shared-component/validate-pin/validate-pin.component';
import { ExpenseClassificationComponent } from './shared-component/expense-classification/expense-classification.component';
//#endregion internal Imports

@NgModule({
  declarations: [
    ...LayoutComponents,
    CustomPipes,
    ...SharedComponents,
    AdminLayoutComponent,
    PlanOfferedComponent,
    FreeTrialReminderComponent,
    ManagePinComponent,
    ValidatePinComponent,
    ExpenseClassificationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PrimeNgModules,
    NgxSpinnerModule,
    NgOtpInputModule
  ],
  exports: [
    AdminSideBarComponent,
    ...SharedComponents,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModules,
    CustomPipes,
    NgxSpinnerModule,
  ],
})
export class CoreModule {}
