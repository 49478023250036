<div class="form-group">
  <label for="fieldOption.label" class="form-label">
    {{ fieldOption.name }}
    <span *ngIf="fieldOption.validator[0].required">*</span>

  </label>
  <p-chips
    [style]="{ width: '100%' }"
    inputStyleClass="w-100"
    [(ngModel)]="valueOfData"
    [separator]="separatorExp"
    [placeholder]="fieldOption.place_holder"
    [disabled]="fieldOption.disabled"
    [required]="fieldOption.validator.required"
    [name]="fieldOption.name"
    (ngModelChange)="onInputChange()"
    (change)="onInputChange()"
  ></p-chips>
  <span class="text-danger" *ngIf="requiredError"
    >{{ fieldOption.name }} is required</span
  >

</div>
