//#region Imports
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'app/modules/authentication/services/authentication.service';
import { CommonService } from '../services/common.service';
//#endregion Imports

//#region Internal Imports

//#endregion Internal Imports

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  nonToken: boolean = false;
  url = ['login', 'forgot_password', 'reset_password', 'organization_sign_up'];
  //#region Constructor Methods
  constructor(
    private _authService: AuthenticationService,
    private commonService: CommonService
  ) {}
  //#endregion Constructor Methods

  //#region Intercept Methods
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let isNoToken = this.containsValueFromArray(request.url);
    let isS3Url = request.url.indexOf('s3')
    if(isS3Url != -1) {
      return next.handle(request);
    }
    // Create headers to be added
    let additionalHeaders: HttpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    });
    if (isNoToken) {
      additionalHeaders = additionalHeaders.set(
        'content-type',
        'application/json; charset=utf-8'
      );
    } else if (request.url.indexOf('expense_transaction/upload') != -1 || request.url.indexOf('money_request_attachment/') != -1) {
      additionalHeaders = additionalHeaders
        .set('Authorization', 'Token ' + this._authService.getToken())
        .set('processData', 'false')
        .set('mimeType', 'multipart/form-data');
    } else {
      // Authorization header added for default case

      additionalHeaders = additionalHeaders
        .set('Authorization', 'Token ' + this._authService.getToken())
        .set('content-type', 'application/json; charset=utf-8');
    }

    // Clone the request and append additional headers
    request = additionalHeaders.keys().reduce((req, headerName) => {
      return req.clone({
        headers: req.headers.append(
          headerName,
          additionalHeaders.get(headerName) || ''
        ),
      });
    }, request);
    return next.handle(request);
  }
  //#endregion Intercept Methods

  //#region Helpers Methods
  containsValueFromArray(str) {
    for (let i = 0; i < this.url.length; i++) {
      if (str.includes(this.url[i])) {
        return true;
      }
    }
    return false;
  }
  //#endregion Helpers Methods
}

// post(url: string, data: Object = new Object()): Observable<any> {
//   var options = { headers: headersLocal };
//   var response = this.http.post<any>(url, data, options)
//   return response;
// }
