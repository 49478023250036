<div class="form-group">
  <label for="fieldOption.label" class="form-label">
    {{ fieldOption.name }}
    <span *ngIf="fieldOption.validator[0].required">*</span>

  </label>
  <input
    type="text"
    pInputText
    [style]="{ width: '100%' }"
    [(ngModel)]="valueOfData"
    [required]="fieldOption.validator.required"
    [name]="fieldOption.name"
    [placeholder]="fieldOption.place_holder"
    [disabled]="fieldOption.disabled"
    (ngModelChange)="onInputChange()"
    (change)="onInputChange()"
  />
  <span class="text-danger" *ngIf="requiredError"
    >{{ fieldOption.name }} is required</span
  >
  <span class="text-danger" *ngIf="requiredMin"
    >{{ fieldOption.name }} can have min. value of
    {{ fieldOption.validator[0].min }}</span
  >
  <span class="text-danger" *ngIf="requiredMax"
    >{{ fieldOption.name }} can have max. value of
    {{ fieldOption.validator[0].max }}</span
  >
</div>
