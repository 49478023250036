import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  BasicFieldData,
  InputField,
  TemplateControl,
} from 'app/core/model/common/input-field.model';

@Component({
  selector: 'app-dynamic-template',
  templateUrl: './dynamic-template.component.html',
  styleUrls: ['./dynamic-template.component.scss'],
})
export class DynamicTemplateComponent {
  @Input() advanceTemplate: TemplateControl;
  @Input() commuteControl: any;
  @Output() templateChange = new EventEmitter<TemplateControl>(); // Output event
  origin_cord = null;
  dest_cord = null;
  options =[]
  counyrt=0
  constructor() {}



  handleNumberFieldChange(basicData:BasicFieldData){
    let index=this.advanceTemplate.control.findIndex(x=>x.id === basicData.id);
    let control = this.advanceTemplate.control[index];
    control.value = basicData.value
    control.valid = basicData.valid
  }
  handleFieldChange(updatedField: InputField) {
    let valid = true;
    let index = this.advanceTemplate.control.findIndex(x=>x.id === updatedField.id);
    this.advanceTemplate.control[index].value = updatedField.value;
    this.advanceTemplate.control[index].valid = updatedField.valid;
    this.advanceTemplate.control.forEach((element) => {
      valid = valid && element.valid;
    });
    if (updatedField.type === 'autocomplete') {
      valid = this.processDataForCommute(updatedField);
    }
    this.advanceTemplate.valid = valid;
    this.templateChange.emit(this.advanceTemplate);
  }

  processDataForCommute(updatedField): boolean {
    let index = this.advanceTemplate.control.indexOf(updatedField);
    let currentValue = this.advanceTemplate.control[index];
    if (currentValue.valid) {
      if (currentValue.label == 'lbl_origin') {
        this.origin_cord = currentValue.meta;
      } else if (currentValue.label == 'lbl_destination') {
        this.dest_cord = currentValue.meta;
      } else {
        return true;
      }
      if (this.origin_cord && this.dest_cord) {
        const distance = this.calculateDistance(
          this.origin_cord.latitude,
          this.origin_cord.longitude,
          this.dest_cord.latitude,
          this.dest_cord.longitude
        );
        if (distance != 0 || distance != undefined) {
          let _index = this.advanceTemplate.control.findIndex(
            (x) => x.label == 'lbl_mode'
          );
          this.options =this.setCommuteMode(distance);
          this.advanceTemplate.control[_index].options = this.options;
        }
      }
    } else {
      return false;
    }
    return true;
  }

  calculateDistance(lat1, lon1, lat2, lon2) {
    const earthRadiusKm = 6371; // Radius of the Earth in kilometers

    // Convert latitude and longitude from degrees to radians
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;

    // Calculate the distances
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in kilometers
    const distance = earthRadiusKm * c;

    return distance;
  }

  setCommuteMode(distance) {
    let chipControl = [];
    this.commuteControl.forEach((expenseType) => {
      if (
        expenseType.control.lbl_from_range <= distance &&
        distance <= expenseType.control.lbl_to_range
      ) {
        chipControl = chipControl.concat(expenseType.chipControl[0]);
      }
    });
    // Return unique values
    return chipControl.map(option => ({ name: option }));

  }
}
