import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashIfZero'
})
export class DashIfZeroPipe implements PipeTransform {
  transform(value:  number | string): any {
    const numericValue = Number(value);

    if (numericValue === 0 || numericValue === null || numericValue === undefined  || isNaN(numericValue)) {
      return '-';
    } else {
      return value;
    }
  }
}
