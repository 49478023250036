//#region Imports
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
//#endregion Imports

//#region Internal Imports
import { CommonService } from '../services/common.service';
//#endregion Internal Imports

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requestCounterMap: { [key: string]: number } = {}; // Track request counts for each URL

  //#region Constructor Methods
  constructor(private spinner: CommonService) {}
  //#endregion Constructor Methods

  //#region Intercept Methods

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const showLoader = request.headers.get('Show-Loader') === `true`;
    const url = request.urlWithParams;
    if (url.includes("reset-loader-object")){
      this.resetRequestCounterMap();
      return null;
    }
    if (showLoader) {
      // If showLoader is true, increment requestCounter for the URL and show the loader
      if (!this.requestCounterMap[url]) {
        this.requestCounterMap[url] = 1;
        this.spinner.showLoader();
      } else {
        this.requestCounterMap[url]++;
      }
    }

    return next.handle(request).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          // When an HttpResponse is received, decrement requestCounter and hide loader if necessary
          if (this.requestCounterMap[url] > 0) {
            this.requestCounterMap[url]--;
            if (this.requestCounterMap[url] === 0) {
              delete this.requestCounterMap[url];
              this.spinner.hideLoader();
            }
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handle errors and decrement requestCounter, hide loader if necessary
        if (this.requestCounterMap[url] > 0) {
          this.requestCounterMap[url]--;
          if (this.requestCounterMap[url] === 0) {
            delete this.requestCounterMap[url];
            this.spinner.hideLoader();
          }
        }
        return throwError(error);
      })
    );
  }
  //#endregion Intercept Methods

  //#region Helpers Methods
  resetRequestCounterMap() {
    this.requestCounterMap = {};
  }
  //#endregion Helpers Methods
}
