import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  items: MenuItem[];

  constructor(
    private commonSerivce: CommonService,
    private appData: AppDataService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.items = [
      {
        label: this.appData.connectedUser.email,
        style: { 'margin-left': 'auto' },
        icon: 'pi pi-fw pi-user',
        command: (event: any) => {},
      },
      {
        label: 'Logout',
        icon: 'pi pi-fw pi-power-off',
        command: (event: any) => {
          this.logout();
        },
      },
    ];
    this.listenToLoading();
    if (this.appData.connectedUser.show_stats == 0 && !this.appData.connectedUser.control_panel_active){
      this.logout();
    }
  }

  logout(): void {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
    localStorage.clear();

    this.commonSerivce.logout().subscribe((x) => {
      document.cookie.split(';').forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      });
      this.router.navigate(['/login']);
    });
  }

  listenToLoading(): void {
    this.commonSerivce.data$.subscribe((loading) => {
      if (loading == 'show') {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }

  openHomePage(): void {
    this.router.navigate(['/admin-panel/']);
  }
}
