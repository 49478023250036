//#region imports
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
//#endregion imports

//#region internal imports
import { environment } from 'environments/environment';
//#endregion internal imports

@Injectable()
export class HttpProvider {
  constructor(private http: HttpClient) {}

  toHttpParams(obj: Object) {
    if (obj) {
      return {
        params: Object.getOwnPropertyNames(obj).reduce(
          (p, key) => p.set(key, obj[key]),
          new HttpParams()
        ),
      };
    }
  }

  private createOptions(
    showLoader: boolean,
    uniqueParam?: string,
    options?: any
  ): { headers?: HttpHeaders; params?: HttpParams } {
    let headers: HttpHeaders | undefined;
    if (showLoader) {
      headers = new HttpHeaders().set('Show-Loader', `true`);
    }

    let params = new HttpParams();
    if (uniqueParam) {
      params = params.set('uniqueParam', uniqueParam);
    }
    if (options && options.params) {
      options.params.params.updates.forEach((key) => {
        params = params.set(key.param, key.value);
      });
    }

    return { headers, params };
  }

  protected get(
    uri: string,
    showLoader = true,
    options?: any
  ): Observable<any> {
    const { headers, params: queryParams } = this.createOptions(
      showLoader,
      undefined,
      options
    );
    const requestOptions = { params: queryParams, headers: headers };
    return this.http.get(environment.apiUrl + uri, requestOptions);
  }

  protected getById(
    uri: string,
    id: string | number,
    showLoader = true,
    options?: any
  ): Observable<any> {
    const { headers, params: queryParams } = this.createOptions(
      showLoader,
      undefined,
      options
    );
    const requestOptions = { params: queryParams, headers: headers };
    return this.http.get(environment.apiUrl + uri + '/' + id, requestOptions);
  }

  protected post(
    uri: string,
    params: any,
    showLoader = true,
    options?: any
  ): Observable<any> {
    const { headers, params: queryParams } = this.createOptions(
      showLoader,
      undefined,
      options
    );
    const requestOptions = { params: queryParams, headers: headers };
    return this.http.post(environment.apiUrl + uri, params, requestOptions);
  }

  protected patch(
    uri: string,
    params: any,
    showLoader = true,
    options?: any
  ): Observable<any> {
    const { headers, params: queryParams } = this.createOptions(
      showLoader,
      undefined,
      options
    );
    const requestOptions = { params: queryParams, headers: headers };
    return this.http.patch(environment.apiUrl + uri, params, requestOptions);
  }

  protected put(
    uri: string,
    params: any,
    showLoader = true,
    options?: any
  ): Observable<any> {
    const { headers, params: queryParams } = this.createOptions(
      showLoader,
      undefined,
      options
    );
    const requestOptions = { params: queryParams, headers: headers };
    return this.http.put(environment.apiUrl + uri, params, requestOptions);
  }

  protected delete(
    uri: any,
    params?: any,
    showLoader = true,
    options?: any
  ): Observable<any> {
    const { headers, params: queryParams } = this.createOptions(
      showLoader,
      undefined,
      options
    );
    const requestOptions = { params: queryParams, headers: headers };
    return this.http.delete(environment.apiUrl + uri, {
      body: params,
      ...requestOptions,
    });
  }

  protected getBlob(
    uri: string,
    showLoader = true,
    params?: any
  ): Observable<HttpResponse<Blob>> {
    const { headers } = this.createOptions(showLoader, undefined);
    const requestOptions = { headers: headers };
    return this.http.get(environment.apiUrl + uri, {
      params,
      responseType: 'blob',
      observe: 'response',
      ...requestOptions,
    });
  }

  protected getBlobPost(
    uri: string,
    params?: any
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(environment.apiUrl + uri, params, {
      responseType: 'blob',
      observe: 'response',
    });
  }
  protected PostBlob(uri: string, params?: any): Observable<any> {
    return this.http.post(environment.apiUrl + uri, params, {
      responseType: 'blob',
    });
  }

  protected exportCSV(uri: string, params?: any, showLoader = true) {
    const { headers } = this.createOptions(showLoader, null);
    const requestOptions = { headers: headers };
    return this.http.post(environment.apiUrl + uri, params, {
      responseType: 'blob',
      ...requestOptions
    });
  }

  protected getExternalEndpoint(
    uri: string,
    showLoader = true,
    options?: any
  ): Observable<any> {
    const { headers, params: queryParams } = this.createOptions(
      showLoader,
      undefined,
      options
    );
    const requestOptions = { params: queryParams, headers: headers };
    return this.http.get(uri, requestOptions);
  }

  protected postSimple(
    uri: string,
    data:any,
    showLoader = true,
    options?: any
  ): Observable<any> {
    let headers: HttpHeaders | undefined;
    headers = new HttpHeaders().set('Show-Loader', `true`);
    options= {
      headers:headers,
      responseType: 'blob',
      observe: 'response',
    }
    return this.http.post(environment.apiUrl + uri, data, options);
  }
}
