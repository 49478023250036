import { Injectable } from '@angular/core';
import * as forge from 'node-forge';

import { public_key } from '../../core/key/public.key';


@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private publicKey: string; // Your server's public key

  constructor() {
    // Set your server's public key here
    this.publicKey = public_key;
  }

  encrypt(data: string): string {
    const publicKey = forge.pki.publicKeyFromPem(this.publicKey);
    const encryptedData = publicKey.encrypt(data, 'RSA-OAEP');

    return forge.util.encode64(encryptedData);
  }
}
