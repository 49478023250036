//#region imports
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/modules/authentication/services/authentication.service';
import { CommonService } from '../services/common.service';
//#endregion imports

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private common: CommonService,
    private toastr: ToastrService,
    private authService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        let displayText = '';
        this.common.hideLoader();
        if (error.status === 403) {
        } else if (error.status === 401) {
          if (error.statusText == 'Unauthorized') {
            this.toastr.error(
              error.statusText + ': ' + error.error.message,
              'Error',
              { enableHtml: true }
            );
          }
          localStorage.clear();
          this.authService.logout().subscribe(
            (result) => {
              this.router.navigate(['/login']);
            },
            (error) => {
              this.router.navigate(['/login']);
            }
          );
          // }
        } else if (error.status >= 400 && error.status < 500) {
          Object.entries(error.error).forEach(([key, value]) => {
            displayText = key + ' :' + (Array.isArray(value) ? value[0] : value);
          });
         
          this.toastr.error(error.statusText + ': ' + displayText, 'Error', {
            enableHtml: true,
          });
          // tslint:disable-next-line: no-string-literal
          errorMessage = error.error;
        } else if (error.status === 500) {
          try{
            this.toastr.error(
              error.error.error,
              'Internal Server Error'
            );
          }catch(error){
            this.toastr.error(
              'There was issue at server side while processing your request',
              'Internal Server Error'
            );
          }

          //          this.router.navigate(['/error/accessright']);
        }
        return throwError(errorMessage);
      })
    );
  }
}
