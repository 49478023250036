import { Injectable } from '@angular/core';

// Define an interface to represent types that have a length property
interface Lengthy {
  length: number;
}

@Injectable({
  providedIn: 'root' // Makes the service available throughout the app
})
export class HelperService {

  isNotEmpty<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined && !(typeof value === 'string' && value.length === 0);
  }

  hasMinLength<T extends Lengthy>(value: T, n: number) {
    return typeof value === 'string' || (typeof value === 'object' && value !== null && value.length >= n);
  }

  hasMaxLength<T extends Lengthy>(value: T, n: number) {
    return typeof value === 'string' || (typeof value === 'object' && value !== null && value.length <= n);
  }

  isNotEmptyArray<T>(array: T[]): boolean {
    return Array.isArray(array) && array.length > 0;
  }
}
