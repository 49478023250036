<div class="scrollbar" id="style-1">
  <h5 class="my-2">Notifications</h5>

  <ul style="max-height: 70vh">
    <li *ngFor="let n of userNotification">
      <div class="d-flex">
        <div class="p-2">
          <p-avatar
            [icon]="n.icon" 
            [style]="{ 'background-color': '#EAEAFF', color: '#3D5CFF' }"
            size="large"
            shape="circle"
          ></p-avatar>
        </div>

        <div>
          <h6>{{ n.title }}</h6>

          <p class="m-0">{{ n.body }}</p>
        </div>
      </div>
    </li>
  </ul>
</div>
