<div class="m-title">Settings</div>
<ul>
  <li *ngIf="isprimaryaccount && isadmin">
    <div class="d-flex p-2 baseline">
      <i class="fa fa-user mt-2 me-3 text-primary"></i>

      <a (click)="toggleOnBoardStatus()" href="javascript:void(0);">
        {{enableOnBoard}}
      </a>

    </div>
  </li>
  <li *ngIf="isprimaryaccount && isadmin">
    <div class="d-flex p-2">
      <i class="pi pi-file  mt-2 me-3 text-primary"></i>

      <a (click)="handleRedirection()" href="javascript:void(0);">
        Manage/View Subscription
      </a>

    </div>
  </li>
  <li>
    <div class="d-flex p-2 mt-1">
      <i class="pi pi-key  mt-2 me-3 text-primary"></i>
      <a (click)="handleChangePassword()" href="javascript:void(0);">
        Change Password
      </a>
    </div>
    <div class="d-flex p-2 mt-1">
      <a (click)="handleViewDispute()" href="javascript:void(0);">
        <i class="pi pi-ticket mt-2 me-3 text-primary"></i>
        Tickets
      </a>
    </div>
  </li>
</ul>