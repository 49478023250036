//#region Imports
import { Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';
//import { TranslateService } from '@ngx-translate/core';
//#endregion Imports

//#region Internal Imports
import { AppInjector } from '../common/app.injector';
import { ExcelReaderService } from '../services/excel-reader.service';
import { RouteHistoryService } from '../services/route-history.service';
//#endregion Internal Imports

export abstract class BaseComponent {
  //#region Variables Declarations
  protected toasterService: ToastrService;
  protected appdataService: AppDataService;
  protected injector: Injector;
  protected excelReaderUtil: ExcelReaderService
  protected routerHistoryService: RouteHistoryService
//  protected translateService: TranslateService;
  //#endregion Variables Declarations

  //#region  Constructor
  protected constructor() {
    this.injector = AppInjector.getInjector();
    this.toasterService = this.injector.get(ToastrService);
    this.appdataService = this.injector.get(AppDataService);
    this.excelReaderUtil = this.injector.get(ExcelReaderService);
    this.routerHistoryService = this.injector.get(RouteHistoryService);
    //this.translateService = injector.get(TranslateService);
  }
  //#endregion Constructor

  //#region
  getCurrentStage(stage) {
    if (this.appdataService.applicationStage) {
      let index = this.appdataService.applicationStage.findIndex(
        (x) => x.name == stage
      );
      return this.appdataService.applicationStage[index];
    }
  }

  getInitals(data: string) {
    if (data != null) {
      let _name = data.split(' ');
      let inital = _name[0].substring(0, 1) + _name[1].substring(0, 1);
      return inital.toUpperCase();
    } else {
      return 'S';
    }
  }

  //#endregion
}
