import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'disputepipe',
  })
  export class DisputeStatusPipe implements PipeTransform {
    transform(source:string) {
      if (!source) {
        return undefined;
      }
  
      let statusName="";
      if(source.toLocaleLowerCase() == "p"){
        statusName="Pending";
      }else if(source.toLocaleLowerCase() == "v"){
        statusName="Resolved";
      }
      return statusName;
  
    }
  }