//#region Imports
import { EventEmitter, Output, Component, Input, SimpleChanges } from '@angular/core';
import { AdminMenu } from '../../model/common/admin-menu-model';
import { LoggedUser } from '../../model/users/logged-user.modal';

import MainMenu from '../../../../assets/data/main-menu.json';
import { Constant } from '../../common/constant';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';
//#endregion Imports

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent {
  @Output() sideBarEvent = new EventEmitter<boolean>();
  @Output() userSettingEvent = new EventEmitter<boolean>();
  @Input() loggedUser: LoggedUser;
  @Input() mainSideBarStatus: boolean = false;
  @Input() hide_menu: boolean = false;

  initals: string = '';
  sideMenuItem: AdminMenu[] = [];
  sideBarStatus: boolean;
  currentRole:string;
  currentMode:string="";
  disableLink:boolean=false;
  //#region Constructor
  constructor(private appData:AppDataService) {

  }
  //#endregion Constructor

  //#region Init

  ngOnInit() {
    this.currentRole = this.appData.currentUserRole
    this.loggedUser = this.appData.connectedUser;

    this.disableLink = false;
    this.setSideMenu();
    this.sideBarStatus = JSON.parse(localStorage.getItem('sideBarStatus'));
    this.sideBarEvent.emit(this.sideBarStatus);
    this.appData.role$.subscribe(
      x=>{

        this.currentRole = x.role_name;
        this.setSideMenu();
      }
    )
    let ismobile = this.isMobileView();
    if(ismobile){
      this.sideBarStatus =false
    }
    if(this.hide_menu){
      this.sideBarEvent.emit(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.loggedUser && this.loggedUser.name) {
      let inti = this.loggedUser.name.split('');
      this.initals = inti[0]+inti[1]
    }
    if(this.mainSideBarStatus){
      this.sideBarStatus = false
      this.toggleSideBar();
    }else{
      this.sideBarStatus = true
      this.toggleSideBar();

    }
  }

  //#endregion

  //#region Helper/Action
  setSideMenu() {
    if(this.hide_menu){

    }else{
      if(this.currentRole.toLocaleLowerCase() == Constant.RoleName.Admin.toLocaleLowerCase() && this.loggedUser.primary_account && !this.loggedUser.status){
        this.sideMenuItem = this.getOnBoardMenu();
        this.currentMode = "Admin";
      }else{
        if (this.currentRole.toLocaleLowerCase() == Constant.RoleName.Admin.toLocaleLowerCase()) {
          this.sideMenuItem = this.getAdminMenu();
        this.currentMode = "Admin";
        } else if (this.currentRole.toLocaleLowerCase() == Constant.RoleName.Finance.toLocaleLowerCase()) {
          this.sideMenuItem = this.getFinanceMenu();
        this.currentMode = "Finance";
        } else if (this.currentRole.toLocaleLowerCase() == Constant.RoleName.Manager.toLocaleLowerCase()) {
          this.sideMenuItem = this.getManagerMenu();
        this.currentMode = "Manager";
        } else if (this.currentRole.toLocaleLowerCase() == Constant.RoleName.User.toLocaleLowerCase()) {
          this.sideMenuItem =this.getUserMenu();
        this.currentMode = "Employee";
        }
      }
      if(this.appData.connectedUser.kyc_status != "C"){
        // if (this.currentMode != 'Employee'){
        let index = this.sideMenuItem.findIndex(item => item.name == "Money Requests")
        if(index != -1){
          this.sideMenuItem[index].active = false;
        }
        // }else{
        //   // let index = this.sideMenuItem.findIndex(item => item.name == "My Wallet")
        //   // if(index != -1){
        //   //   this.sideMenuItem[index].active = false;
        //   // }
        // }
      }
    }


  }

  toggleSideBar(data = null) {
    if(data != null){
      this.sideBarStatus = !this.sideBarStatus
    }
    if(this.sideBarStatus){
      this.sideBarEvent.emit(this.sideBarStatus);
      localStorage.setItem('sideBarStatus', this.sideBarStatus.toString());
    }
    else{
      this.sideBarStatus = false;
      this.sideBarEvent.emit(false);
      localStorage.setItem('sideBarStatus', false.toString());
    }
  }

  openUserSetting(){
    this.userSettingEvent.emit(true)
  }
  //#endregion

  //#region
  getOnBoardMenu():AdminMenu[]{
    return MainMenu.onboard;
  }

  getAdminMenu(): AdminMenu[] {
    return MainMenu.admin;
  }

  getFinanceMenu():AdminMenu[]{
    return MainMenu.finance;
  }

  getManagerMenu():AdminMenu[]{
    return MainMenu.manager;
  }

  getUserMenu():AdminMenu[]{
    return MainMenu.user;
  }

  isMobileView(): boolean {
    const mobileQuery = window.matchMedia('(max-width: 480px)'); // Adjust the width as needed
    return mobileQuery.matches;
  }

  //#endregion
}
