import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountLabel'
})
export class AmountLabelPipe implements PipeTransform {
  transform(value: number): any {
    const prefix = value >= 0 ? ' Cr.' : ' Dr.';
    const cssClass = value >= 0 ? 'text-success' : 'text-danger';

    return prefix;
  }
}
