import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusLabel'
})
export class KYCStatusLabelPipe implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case 'P':
        return 'Pending';
      case 'C':
        return 'Completed';
      case 'I':
        return 'Initiated';
      default:
        return 'Pending';
    }
  }
}
