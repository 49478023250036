import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HelperService } from 'app/core/feature/helper/helper.service';
import { BasicFieldData, InputField } from 'app/core/model/common/input-field.model';

@Component({
  selector: 'app-input-chips',
  templateUrl: './input-chips.component.html',
  styleUrls: ['./input-chips.component.scss'],
})
export class InputChipsComponent {
  separatorExp: RegExp = /,| /;
  @Input() fieldOption: InputField = new InputField();
  @Output() fieldChange = new EventEmitter<InputField>(); // Output event
  @Input() pk: string = '';


  valueOfData: any;
  requiredError: boolean = false;

  constructor(private helperService: HelperService) {
    this.valueOfData = this.fieldOption.value
  }

  ngOnInit(){
    this.fieldOption.pk = this.pk
    this.valueOfData = this.fieldOption.value;
  }

  onInputChange() {
    this.fieldOption.value = this.valueOfData;
    this.fieldOption.options = this.fieldOption.value;
    let valid = true;
    const { validator, value } = this.fieldOption;
    if (validator) {
      if (
        validator[0].required &&
        !this.helperService.isNotEmptyArray(this.fieldOption.options)
      ) {
        valid = false;
        this.requiredError = true;
      } else {
        this.requiredError = false;
      }
    }
    this.fieldOption.valid = valid;
    this.fieldChange.emit(this.fieldOption);
  }
}
