<ng-container  *ngIf="on_board_mode">
  <span class="text-danger">Disable On boarding mode to change roles</span>
  <br>
</ng-container>
<div [ngClass]="{'op-5': on_board_mode}">
  <strong>Change Role</strong>
  <ul>
    <li *ngFor="let role of loggedInUser.role">
      <div class="d-flex p-2" (click)="setRole(role)" >
        <i class="fa fa-user mt-2"></i>
        <span class="px-3">
          {{role.role_name}}
        </span>
        <div class="float-end" *ngIf="role.role_name == currentRole">
          <i class="fa fa-check-circle text-success"></i>
        </div>
      </div>
    </li>
  </ul>
  
</div>
