<!-- <h4 style="font-weight: normal">Profile</h4> -->
<div class="text-center p-2">
  <span class="avatar p-2">
    <span class="avatar-content">{{initals}}</span> </span><br />
  <div class="my-2">
    <span class="user-name fw-bolder">{{connectedUser.first_name }} {{connectedUser.last_name}}</span>
  </div>
  <div class="my-2">
    <span class="user-name fw-bolder">Emp ID #{{employee_id }}</span>
  </div>
  <div><a href="javascript:void(0);" (click)="logout()">Logout</a></div>
</div>
<p-divider></p-divider>
