import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteHistoryService {
  private lastRoute: string = '';

  setLastRoute(route: string): void {
    this.lastRoute = route;
    localStorage.setItem('lastRoute', JSON.stringify(this.lastRoute));
  }

  getLastRoute(): string {
    this.lastRoute = localStorage.getItem('lastRoute');
    return this.lastRoute;
  }
}
