<div class="admin-menu">
  <div class="py-4 pl-2">
    <h3>Settings</h3>
  </div>
  <div class="pl-1" *ngFor="let menu of adminMenu">
    <div class="header-text">{{ menu.name | uppercase }}</div>
    <div class="list-group">
      <a
        *ngFor="let sub of menu.child"
        [routerLink]="sub.routerLink"
        routerLinkActive="active"
        ><i [class]="sub.icon"></i><span>{{ sub.name }}</span></a
      >
    </div>
  </div>
</div>


<p-dialog header="Contact Detail" [(visible)]="view_contact" [modal]="true" [style]="{width: '25vw'}">
  <div class="row">
    <div class="col-sm-12">
      <h4> Sales</h4>
      <p>Email : <a href="mailto:support@bizpay.co.in">support&#64;bizpay.co.in</a></p>
      <hr>
      <h4> Support</h4>
      <p>Email : <a href="mailto:care@bizpay.co.in">care&#64;bizpay.co.in</a></p>

    </div>
  </div>
</p-dialog>