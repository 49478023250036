import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HelperService } from 'app/core/feature/helper/helper.service';
import {
  BasicFieldData,
  InputField,
} from 'app/core/model/common/input-field.model';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent {
  valueOfData: string = '';
  @Input() fieldOption: InputField = new InputField();
  @Output() fieldChange = new EventEmitter<InputField>(); // Output event
  @Input() pk: string = '';

  requiredError: boolean = false;
  requiredMin: boolean = false;
  requiredMax: boolean = false;

  constructor(private helperService: HelperService) {
    this.valueOfData = this.fieldOption.value;
  }

  ngOnInit() {
    this.fieldOption.pk = this.pk
    this.valueOfData = this.fieldOption.value;
  }

  onInputChange() {
    this.fieldOption.value = this.valueOfData;
    let valid = true;
    const { validator, value } = this.fieldOption;
    if (validator) {
      if (validator[0].required && !this.helperService.isNotEmpty(value)) {
        valid = false;
        this.requiredError = true;
      } else {
        this.requiredError = false;
      }
      if (
        validator[0].min &&
        !this.helperService.hasMinLength(value, validator[0].min)
      ) {
        valid = false;
        this.requiredMin = true;
      } else {
        this.requiredMin = false;
      }
      if (
        validator[0].max &&
        !this.helperService.hasMaxLength(value, validator[0].max)
      ) {
        valid = false;
        this.requiredMax = true;
      } else {
        this.requiredMax = false;
      }
    }
    this.fieldOption.valid = valid;
    this.fieldChange.emit(this.fieldOption);
  }
}
