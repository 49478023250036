import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';
import { Constant } from '../../common/constant';
import { LoggedUser, UserRole } from '../../model/users/logged-user.modal';

@Component({
  selector: 'app-setting-menu',
  templateUrl: './setting-menu.component.html',
  styleUrls: ['./setting-menu.component.scss'],
})
export class SettingMenuComponent implements OnInit {

  //#region
  @Output() closeSideBarEvent = new EventEmitter<boolean>();

  loggedInUser: LoggedUser;
  currentRole:string = "";
  on_board_mode:boolean = false;
  //#endregion
  //#region
  constructor(private appData:AppDataService,private route:Router) {}
  //#endregion
  //#region
  ngOnInit(): void {
    this.loggedInUser = this.appData.connectedUser;

    this.currentRole = this.appData.currentUserRole;
    this.on_board_mode = JSON.parse(localStorage.getItem('allow-on-boarding-process')) == null ? false :true;

  }


  setRole(role:UserRole){
    if (this.on_board_mode){
      return
    }
    this.appData.currentUserRole = role.role_name
    this.currentRole =  role.role_name
    this.appData.changeRole(role);
    this.closeSideBarEvent.emit(true);
    if(this.currentRole.toLocaleLowerCase() == Constant.RoleName.Admin){
        this.route.navigateByUrl(Constant.DashboardRoute.Admin)
    }else if(this.currentRole.toLocaleLowerCase() == Constant.RoleName.Finance){
      this.route.navigateByUrl(Constant.DashboardRoute.Finance)
    }else if(this.currentRole.toLocaleLowerCase() == Constant.RoleName.User ){
      this.route.navigateByUrl(Constant.DashboardRoute.User)
    }else if(this.currentRole.toLocaleLowerCase() == Constant.RoleName.Manager){
      this.route.navigateByUrl(Constant.DashboardRoute.Manager)
    };
  }
  //#endregion
}
