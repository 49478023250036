<p-menubar [model]="items" styleClass="">
  <ng-template pTemplate="start">
    
    <img
      src="../../../../assets/img/site-icon.svg"
      alt="Bizpay"
      height="40"
      class="mx-2"
      (click)="openHomePage()"
    />
  </ng-template>
</p-menubar>
<div class="container-fluid">
    <div class="m-4">
        <router-outlet></router-outlet>

    </div>
</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>