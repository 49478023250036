//#region Internal Imports
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AdminSideBarComponent } from './admin-side-bar/admin-side-bar.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { NotificationComponent } from './notification/notification.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingMenuComponent } from './setting-menu/setting-menu.component';
import { SideBarComponent } from './side-bar/side-bar.component';
//#endregion Internal Imports

export const LayoutComponents: any[] = [
  DefaultLayoutComponent,
  SideBarComponent,
  AdminSideBarComponent,
  ProfileComponent,
  NotificationComponent,
  ProfileMenuComponent,
  SettingMenuComponent,
  AdminLayoutComponent
];

//#region Exports All Module
export * from './default-layout/default-layout.component';
export * from './side-bar/side-bar.component';
export * from './admin-side-bar/admin-side-bar.component';
export * from './notification/notification.component';
export * from './profile-menu/profile-menu.component';
export * from './profile/profile.component';
export * from './setting-menu/setting-menu.component';
export * from './admin-layout/admin-layout.component';
//#endregion Exports All Module
