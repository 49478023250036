import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';

@Component({
  selector: 'app-plan-offered',
  templateUrl: './plan-offered.component.html',
  styleUrls: ['./plan-offered.component.scss'],
})
export class PlanOfferedComponent implements OnInit {
  @Output() close_plan = new EventEmitter<boolean>();
  planList: any = [];
  currentuser: any = null;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private appData: AppDataService
  ) {}

  ngOnInit(): void {
    this.currentuser = this.appData.connectedUser;
    this.commonService.getPlanDetails().subscribe((x) => {
      this.planList = x.sort((a, b) => a.id - b.id);
      this.planList = this.planList.splice(0, 3);
      this.planList.forEach((plan) => {
        let detail = plan.description.split('___');
        plan.des = detail[0].split('@');
        plan.features = detail[1]?.split('__');
      });
    });
  }

  openPlanPurchase(data) {
    this.close_plan.emit(true);
    if (data != 1) {
      this.router.navigate(['/administrator/upgrade-subscription/',data]);
      // this.router.navigate(['/administrator/manage-subscription/']);
      
    }
  }
}
