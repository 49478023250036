
          <div class="col-sm-12 col-md-12 col-lg-12 mt-1">
            <div class="card">
              <div class="card-body">
  
                <h5>API Reference</h5>
                <a href="https://www.postman.com/dev-bizpay/workspace/bizpay-api/collection/12811139-620830c1-10c8-45a2-bece-b440fb605d43?action=share&source=copy-link&creator=12811139"
                  target="_blank" style="text-decoration: none;">Download the postman collection
                  <i class="fa fa-download mx-3"></i></a>
                <p-accordion [activeIndex]="0">
                  <p-accordionTab header="Transactions for Accounting">
                    <div class="row">
                      <div class="col-md-6 col-lg-7 request-height">
                        <p>
                          This endpoint returns the transaction data formatted for the purpose of entering the same into
                          ERP/HRM and Accounting systems. The following types of transactions are returned:-
                        </p>
                        <ol>
                          <li>Verified Expenses</li>
                          <li>Funds Loaded to Wallets</li>
                          <li>Funds Withdrawn from Wallets</li>
                        </ol>
                        <small class="url-data"><i>POST</i> </small>
                        <h6>BODY PARAMS</h6>
                        <table class="params-table">
                          <tbody>
                            <tr>
                              <td>
                                <code>pageindex</code>
                                <small class="ms-2">required</small>
                                <p>
                                  Specifies the position the response data should
                                  begin from.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>integration_id</code>
                                <p>
                                  Specifies the unique integration id of your
                                  mapping. If integration id is provided then the
                                  mapping will be used to give the ledger name in
                                  each transaction as required by accounting
                                  systems. If left blank no ledger mapping will be
                                  done "Suspense" will be provided for both debit
                                  and credit ledger.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>from_date</code>
                                <p>
                                  This is the start date of the date range for
                                  which transaction data is needed. Leaving it
                                  blank will provide all data from the start till
                                  "to_date"
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>to_date</code>
                                <p>
                                  This is the end date of the date range for which
                                  transaction data is needed. Leaving it blank
                                  will provide all data "from_date" till current
                                  date.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <h6>RESPONSE</h6>
                        <small class="url-success"><i>SUCCESS</i> </small>
                        <table class="params-table">
                          <tbody>
                            <tr>
                              <td>
                                <code>count</code>
                                <p>Specifies the number of transactions.</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>next</code>
                                <p>This is the next page number.</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>previous</code>
                                <p>This is the previous page number.</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>results</code>
                                <p>Specifies the array of objects.</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>voucher_number</code>
                                <p>Unique identifier of the transaction.</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>date</code>
                                <p>Date of the transaction.</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>status</code>
                                <p>
                                  Denotes the status of the transaction. Incase of
                                  expenses the same will be “Verified” and it will
                                  be null in other cases.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>debit_ledger_name</code>
                                <p>
                                  Denotes the ledger name of the debit account. In
                                  case the integration id of the mapping is not
                                  provided the same will be Suspense by default.
                                  The value of this field also varies based on the
                                  type of transaction it is as follows:-
                                </p>
                                <ol>
                                  <li>
                                    Expense - Ledger name as per mapping against
                                    the expense category, “Suspense” if the
                                    integration id of the mapping is not provided.
                                  </li>
                                  <li>
                                    Funds Loaded - Ledger name against the mapping
                                    for the user whose wallet was loaded,
                                    “Suspense” if the integration id of the
                                    mapping is not. Provided.
                                  </li>
                                  <li>
                                    Funds Withdrawn - “BizPay Pool Account”, it is
                                    recommended that a ledger with this name
                                    should be created within the
                                    accounting/ErP/HRM system to avoid any errors.
                                  </li>
                                </ol>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>credit_ledger_name</code>
                                <p>
                                  Denotes the ledger name of the credit account.
                                  In case the integration id of the mapping is not
                                  provided the same will be Suspense by default.
                                  The value of this field also varies based on the
                                  type of transaction it is as follows:-
                                </p>
                                <ol>
                                  <li>
                                    Expense - Ledger name against the mapping of
                                    the user who created the expense. “Suspense”,
                                    if the integration id of the mapping is not
                                    provided.
                                  </li>
                                  <li>
                                    Funds Loaded - “BizPay Pool Account”, it is
                                    recommended that a ledger with this name
                                    should be created within the
                                    accounting/ErP/HRM system to avoid any errors.
                                  </li>
                                  <li>
                                    Funds Withdrawn - Ledger name against the
                                    mapping of the user from whose wallet the
                                    funds are withdrawn. “Suspense”, in case the
                                    integration id of the mapping is not provided.
                                  </li>
                                </ol>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>user_fullname</code>
                                <p>
                                  Full name of the user who related to the
                                  transaction.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>effective_date</code>
                                <p>The effective date of the transaction.</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>debit_amount</code>
                                <p>
                                  Amount of the debit side of the accounting
                                  entry.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>credit_amount</code>
                                <p>
                                  Amount of the credit side of the accounting
                                  entry.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>expense_type_name</code>
                                <p>
                                  The expense category name in BizPay. This is
                                  relevant only in case of transactions being of
                                  “expense” type. In other cases the same will be
                                  null.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>narration</code>
                                <p>
                                  System generated narration for the accounting
                                  entry. The value of this field varies based on
                                  the type of transaction as follows:-
                                </p>
                                <ol>
                                  <li>
                                    Expense - The notes added during creation or
                                    edit of an expense. Funds Loaded or Funds
                                    Withdrawn - The description against the
                                    transaction as appears in BizPay.
                                  </li>
                                </ol>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>team</code>
                                <p>
                                  The name of the team to which the user related
                                  with the transaction is a member. Returns a
                                  blank string in case the user doesn’t belong to
                                  any team.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>expense_bucket_name</code>
                                <p>
                                  The name of the expense bucket of the expense.
                                  Relevant only for transaction of type “expense”,
                                  in other cases the same will be null.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>transaction_type</code>
                                <p>
                                  Denotes the type of transaction, this can have
                                  the following values
                                </p>
                                <ol>
                                  <li>Expense</li>
                                  <li>Funds Loaded</li>
                                  <li>Funds Withdrawn</li>
                                </ol>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-md-6 col-lg-5">
                        <div class="bg-dark">
                          <pre>
  
        <span class="h-purple">import</span> requests
        <span class="h-purple">import</span> json
  
        url = <span class="h-green">"</span>{{ '{{' }}<span class="text-danger">BASE URL</span>{{ '}}' }}<span class="h-green">/rest/v1/transaction_for_accounting/"</span>
  
        payload = {{ '{' }}
                    <span class="h-green">'pageindex'</span>: <span class="h-green">'1'</span>,
                    <span class="h-green">'integration_id'</span>: <span class="h-green">''</span>,
                    <span class="h-green">'from_date'</span>: <span class="h-green">''</span>,
                    <span class="h-green">'to_date'</span>: <span class="h-green">''</span>
                  {{ '}' }}
  
        headers = {{ '{' }}
          <span class="h-green">'Content-Type'</span>: <span class="h-green">'application/json'</span>,
          <span class="h-green">'Authorization'</span>: <span class="h-green">'apikey <span class="text-danger">YOUR_API_KEY</span>'</span>
          {{ '}' }}
  
        response = requests.request(<span class="h-green">"POST"</span>,url,headers=headers,
                                    data=payload)
                        </pre>
                        </div>
                      </div>
                    </div>
                  </p-accordionTab>
                </p-accordion>
                <p-accordion [activeIndex]="1">
                  <p-accordionTab header="Updating Transaction">
                    <div class="row">
                      <div class="col-md-6 col-lg-7 request-height">
                        <p>
                          This endpoint returns the transaction data formatted for the purpose of entering the same into
                          ERP/HRM and Accounting systems. The following types of transactions are returned:-
                        </p>
                        <ol>
                          <li>Verified Expenses</li>
                          <li>Funds Loaded to Wallets</li>
                          <li>Funds Withdrawn from Wallets</li>
                        </ol>
                        <small class="url-data"><i>POST</i> </small>
                        <h6>BODY PARAMS</h6>
                        <table class="params-table">
                          <tbody>
                            <tr>
                              <td>
                                <code>voucher_number</code>
                                <small class="ms-2">required</small>
                                <p>
                                  Unique identifier of the transaction.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <code>reference_id</code>
                                <small class="ms-2">required</small>
                                <p>
                                  Your unique identifier.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <h6>RESPONSE</h6>
                        <small class="url-success"><i>SUCCESS</i> </small>
                        <table class="params-table">
                          <tbody>
                            <tr>
                              <td>
                                <code>message</code>
                                <p>Message notifying details of success.</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <small class="url-error"><i>ERROR</i> </small>
                        <table class="params-table">
                          <tbody>
                            <tr>
                              <td>
                                <code>message</code>
                                <p>Message notifying details of error.</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-md-6 col-lg-5">
                        <div class="bg-dark">
                      <pre>
  
        <span class="h-purple">import</span> requests
        <span class="h-purple">import</span> json
  
        url = <span class="h-green">"</span>{{ '{{' }}<span class="text-danger">BASE URL</span>{{ '}}' }}<span class="h-green">/rest/v1/transaction_integration/"</span>
  
        payload = {{ '{' }}
                    <span class="h-green">'voucher_number'</span>: <span class="h-green">'1'</span>,
                    <span class="h-green">'reference_id'</span>: <span class="h-green">''</span>,
                  {{ '}' }}
  
        headers = {{ '{' }}
          <span class="h-green">'Content-Type'</span>: <span class="h-green">'application/json'</span>,
          <span class="h-green">'Authorization'</span>: <span class="h-green">'apikey <span class="text-danger">YOUR_API_KEY</span>'</span>
          {{ '}' }}
  
        response = requests.request(<span class="h-green">"POST"</span>,url,headers=headers,
                                    data=payload)
                        </pre>
                        </div>
                      </div>
                    </div>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>
          </div>
