import { Component, Input, OnInit } from '@angular/core';
import { AdminMenu } from '../../model/common/admin-menu-model';
import MainMenu from '../../../../assets/data/main-menu.json';

@Component({
  selector: 'app-admin-side-bar',
  templateUrl: './admin-side-bar.component.html',
  styleUrls: ['./admin-side-bar.component.scss']
})
export class AdminSideBarComponent implements OnInit {
  @Input() menuType :string =null;
  view_contact :boolean = false;
  adminMenu:any[]=[];
  constructor() { }

  ngOnInit(): void {
    if(this.menuType == 'admin'){
      this.adminMenu=MainMenu.adminSideBar;
    }else if(this.menuType == 'employee' || this.menuType == 'manager'){
      this.adminMenu=MainMenu.userSideBar;
    }else{
      this.adminMenu=MainMenu.subscriptions;
    }
  }



}
