import { Pipe, PipeTransform } from '@angular/core';
import { LoggedUser } from '../model/users/logged-user.modal';

@Pipe({
  name: 'initalspipe',
})
export class InitalPipe implements PipeTransform {
  transform(source:LoggedUser) {
    if (!source) {
      return undefined;
    }

    let inital=source.first_name.substring(0, 1)+ source.last_name.substring(0, 1);
    return inital.toUpperCase();

  }
}
// https://stackblitz.com/edit/angular-nevhxm-so?file=src%2Fapp%2Fapp.component.html

// <div *ngFor='let result of results'>
// 	<div [ngClass]='{"myclass": someArray | inArray:result.id }'>
//     {{result.name}}
// 	</div>
// </div>
