import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputField } from 'app/core/model/common/input-field.model';
import moment from 'moment';

@Component({
  selector: 'app-input-calendar',
  templateUrl: './input-calendar.component.html',
  styleUrls: ['./input-calendar.component.scss'],
})
export class InputCalendarComponent {
  valueOfData: any;
  @Input() fieldOption: InputField = new InputField();
  @Input() pk: string = '';
  @Output() fieldChange = new EventEmitter<InputField>(); // Output event

  constructor() {
    this.valueOfData = this.fieldOption.value;
  }

  ngOnInit() {
    this.fieldOption.pk = this.pk
    if (this.fieldOption.value != null)
      this.valueOfData = new Date(this.fieldOption.value);
  }
  ngAfterViewInit() {
  }

  onInputChange() {
    // Emit the updated fieldOption object
    const formattedDate = moment(this.valueOfData).format("YYYY-MM-DD");
    this.fieldOption.value = formattedDate;


    if (this.fieldOption.value != null) {
      this.fieldOption.valid = true;
    }
    this.fieldChange.emit(this.fieldOption);
  }
}
