import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'app/core/services/common.service';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';

@Component({
  selector: 'app-expense-classification',
  templateUrl: './expense-classification.component.html',
  styleUrl: './expense-classification.component.scss',
})
export class ExpenseClassificationComponent {
  @Input() expenseList: any[] = [];
  @Output() closeClasify = new EventEmitter<boolean>();
  index: number = 0;
  selected_data: any;
  currency_data: any;
  merchant_name: string = '';
  upi_id: string = '';
  constructor(
    private appDataService: AppDataService,
    private cmService: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    this.currency_data = this.appDataService.currencyOption;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.expenseList.length != 0) {
      this.selected_data = this.expenseList[0];
      this.renderName(this.selected_data?.merchant_name)
    }
  }

  onPrevious() {
    this.index =
      (this.index - 1 + this.expenseList.length) % this.expenseList.length; // Handle negative index
    this.selected_data = this.expenseList[this.index];
  }

  onNext() {
    this.index = (this.index + 1) % this.expenseList.length;
    this.selected_data = this.expenseList[this.index];
  }

  onClassify(data) {
    let element = {
      id: this.selected_data.id,
      txn_type: data,
    };
    this.cmService.classifyExpense(element).subscribe((resp) => {
      this.expenseList.splice(this.index, 1);
      if (this.expenseList.length == 0) {
        this.closeClasify.emit(false);
        this.reloadRoute();
      } else {
        this.onNext();
      }
    });
  }

  reloadRoute() {
    // Get the current URL using router.url
    const currentUrl = this.router.url;
    this.router.navigate(['dashboard','redirect',currentUrl]);

  }

  renderName(value:string){
    const match = value.match(/^(.+?)\s*\(\s*(.+?)\s*\)$/);
    if (match) {
      this.merchant_name = match[1];
      this.upi_id = match[2]
    } else {
      this.merchant_name = this.selected_data?.merchant_name;
    }
  }

}
