<div class="form-group">
  <label for="fieldOption.label" class="form-label">
    {{ fieldOption.name }}
    <span *ngIf="fieldOption.validator[0].required">*</span>
  </label>
  <input
    type="text"
    pInputText
    [style]="{ width: '100%' }"
    [(ngModel)]="valueOfData"
    [required]="fieldOption.validator.required"
    [name]="fieldOption.name"
    [placeholder]="fieldOption.place_holder"
    [disabled]="fieldOption.disabled"
    (ngModelChange)="onInputChange()"
    (change)="onInputChange()"
    id="autocompleteInput_{{fieldOption.label}}"
  />
  <span class="text-danger" *ngIf="requiredError"
    >{{ fieldOption.name }} is required</span
  >
</div>
