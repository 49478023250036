import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import {
  BasicFieldData,
  InputField,
} from 'app/core/model/common/input-field.model';

@Component({
  selector: 'app-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss'],
})
export class InputDropdownComponent {
  @Input() fieldOption: InputField = new InputField();
  @Input() dropdownList: any;
  @Output() fieldChange = new EventEmitter<InputField>(); // Output event
  @Input() pk: string = '';

  valueOfData: any;
  options: any[];
  requiredError: boolean = false;

  constructor() {
    this.valueOfData = this.fieldOption.value;
    this.fieldOption.pk = this.pk;
  }

  ngOnInit() {
    if (this.dropdownList.length == 0) {
      this.dropdownList = this.fieldOption.options;
    }
    this.fieldOption.pk = this.pk;
    this.valueOfData = {
      name: this.fieldOption.value,
    };
  }

  ngOnChanges(change: SimpleChanges) {
    this.valueOfData = this.fieldOption.value;
  }
  onInputChange() {
    // Emit the updated fieldOption object
    this.fieldOption.value = this.valueOfData.name;
    if (this.valueOfData == null || this.valueOfData == undefined) {
      this.fieldOption.valid = false;
    } else {
      this.fieldOption.valid = true;
    }
    this.fieldChange.emit(this.fieldOption);
    console.log(this.valueOfData);
  }
}
