import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpProvider } from '../common/http-provider.service';
import { Dropdown } from '../model/common/dropdown.model';
import { UserRole } from '../model/users/logged-user.modal';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CommonService extends HttpProvider {

  purchasePlanEmitter = new EventEmitter<boolean>(); // Replace with your event data type
  private dataSubject = new BehaviorSubject<string>('show'); // Initial value is false
  public data$ = this.dataSubject.asObservable();
  loaderCounter: number = 0;
  _isloading: boolean = false;
  //#region ENDPOINTS
  private readonly countryCodeEndpoint = '/country/';
  private readonly stateEndpoint = '/state/';
  private readonly industryEndpoint = '/industry/';
  private readonly cardTypeEndpoint = '/card_type/';
  private readonly expenseBucketCatgoryEndpoint = '/expense_bucket_category/';
  private readonly expenseBucketEndpoint = '/expense_bucket/';
  // private readonly expenseTypeEndpoint ="/expense_bucket_category/";
  private readonly durationEndpoint = '/duration_dropdown/';
  private readonly departmentEndpoint = '/department/';
  private readonly scheduleEndpoint = '/schedule_dropdown/';
  private readonly phoneCodeEndpoint = '/country_dropdown/';
  private readonly expenseTypepEndpoint = '/expense_type_dropdown/';
  private readonly expenseStatusEndpoint = '/expense_status_dropdown/';
  private readonly accountPackageEndpoint = '/account_package/';

  private readonly roleForUserEndpoint = '/role/';
  private readonly teamForUserEndpoint = '/team/';
  private readonly userListForUserEndpoint = '/user/';
  private readonly managerForUserEndpoint = '/manager/';
  private readonly policyForUserEndpoint = '/policy/';
  private readonly limitNameForUserEndpoint = '/limit/';
  private readonly divisionForUserEndpoint = '/division_dropdown/';
  private readonly reportForUserEndpoint = '/report_dropdown/';
  private readonly expenseBucketForUserEndpoint = '/expense_bucket_dropdown/';
  private readonly expenseCategoryForUserEndpoint = '/expense_category/';
  private readonly approverForUserEndpoint = '/approver_dropdown/';
  private readonly verifierForUserEndpoint = '/verifer_dropdown/';
  private readonly organizationProgressEndpoint = '/organization_progress/';
  private readonly userDropDownEndPoint = '/user_dropdown/';
  private readonly policyFrequencyDropDownEndPoint = '/policy_frequency/';
  private readonly subscriptionEndpoint = '/subscription_type/';
  private readonly userNotification = '/app_notifications/';
  private readonly titleEndPoint = '/title_dropdown/';
  private readonly logoutEndPoint = '/logout/';
  private readonly userWithoutKitEndPoint = '/user_without_kit/';
  private readonly ResetLoader = 'reset-loader-object';
  private readonly ExportDataEndPoint = '/export_data/';
  private readonly kitStatusCountEndPoint = '/kit_status_count/';
  private readonly UserApproverEndPoint = '/user_approver_dropdown/';
  private readonly UserVerifierEndPoint = '/user_verifier_dropdown/';
  private readonly mainExpenseTypeEndPoint = '/main_expense_type/';
  private readonly CardDetailEndPoint ='/bank/get_card_details/';
  private readonly CurrencyEndPoint ='/currency_dropdown/';
  private readonly OrganizationCurrencyEndPoint ='/organization_currency/';
  private readonly ExchangeRateEndPoint ='/exchange_historical_data/';
  private readonly setPinEndPoint ='/bank/upi/set_pin/';
  private readonly validatePinEndPoint ='/bank/upi/validate_pin/';
  private readonly PinStatusEndPoint ='/bank/upi/pin_status/';
  private readonly userSelectionEndpoint = '/user_selection/'
  private readonly resetPinRequestEndPoint = '/bank/upi/reset_pin_request/'
  private readonly resetPinVerificationEndPoint = '/bank/upi/otp_pin_request/'
  private readonly expenseClassificationEndpoint = '/expense_classification/'
  private readonly taxTypeEndpoint = '/tax_types/'

  //#endregion

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  //#region QUERY
  getTitles() {
    return this.get(this.titleEndPoint, false);
  }
  getCountryCode(): Observable<Dropdown[]> {
    return this.get(this.countryCodeEndpoint, false);
  }
  getIndustry(): Observable<Dropdown[]> {
    return this.get(this.industryEndpoint, false);
  }
  getCardType(): Observable<Dropdown[]> {
    return this.get(this.cardTypeEndpoint, false);
  }

  getDurationType(): Observable<Dropdown[]> {
    return this.get(this.durationEndpoint, false);
  }

  getDepartment(): Observable<Dropdown[]> {
    return this.get(this.departmentEndpoint, false);
  }

  getSchedule(): Observable<Dropdown[]> {
    return this.get(this.scheduleEndpoint, false);
  }

  getExpenseBucketCategory(): Observable<Dropdown[]> {
    return this.get(this.expenseBucketCatgoryEndpoint, false);
  }

  getCurrencyDropdowm(symbol= 'False'): Observable<Dropdown[]> {
    const queryParams = {
      symbol_only: symbol,
    };
    return this.get(this.CurrencyEndPoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }

  getExpenseBucketCategoryForUser(): Observable<Dropdown[]> {
    const queryParams = {
      user_filter: true,
    };
    return this.get(this.expenseBucketEndpoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }

  getExpenseType(query?: string): Observable<Dropdown[]> {
    if (query) {
      const queryParams = {
        type: query,
      };
      return this.get(this.expenseTypepEndpoint, false, {
        params: this.toHttpParams(queryParams),
      });
    } else {
      return this.get(this.expenseTypepEndpoint, false);
    }
  }

  getState(id): Observable<Dropdown[]> {
    return this.get(`/${id}` + this.stateEndpoint, false);
  }
  //#endregion

  //#region BASED ON USER
  getRoleForUser(): Observable<Dropdown[]> {
    return this.get(this.roleForUserEndpoint, false);
  }
  getUserListForUser(): Observable<Dropdown[]> {
    return this.get(this.userListForUserEndpoint, false);
  }
  getTeamListForUser(): Observable<Dropdown[]> {
    return this.get(this.teamForUserEndpoint, false);
  }

  getDivisionForUser(): Observable<Dropdown[]> {
    return this.get(this.divisionForUserEndpoint, false);
  }

  getPolicyForUser(): Observable<Dropdown[]> {
    return this.get(this.policyForUserEndpoint, false);
  }

  getManagerForUser(): Observable<Dropdown[]> {
    return this.get(this.managerForUserEndpoint, false);
  }

  getLimitNameForUser(): Observable<Dropdown[]> {
    return this.get(this.limitNameForUserEndpoint, false);
  }

  getReportForUser(): Observable<Dropdown[]> {
    const queryParams = {
      is_user: true,
    };
    return this.get(this.reportForUserEndpoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }

  getExpenseBucket(): Observable<Dropdown[]> {
    return this.get(this.expenseBucketForUserEndpoint, false);
  }

  getExpenseBucketForUser(id: number): Observable<Dropdown[]> {
    const queryParams = {
      user_id: id,
    };
    return this.get(this.expenseBucketForUserEndpoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }

  getApproverForUser(): Observable<Dropdown[]> {
    return this.get(this.approverForUserEndpoint, false);
  }

  getUserApprover(): Observable<Dropdown[]> {
    return this.get(this.UserApproverEndPoint, false);
  }

  getVerifierForUser(): Observable<Dropdown[]> {
    return this.get(this.verifierForUserEndpoint, false);
  }

  getUserVerifier(): Observable<Dropdown[]> {
    return this.get(this.UserVerifierEndPoint, false);

  }

  getExpenseCategory(): Observable<Dropdown[]> {
    return this.get(this.expenseCategoryForUserEndpoint);
  }

  getUserDropdown(data?: string): Observable<Dropdown[]> {
    if (data == undefined) {
      return this.get(this.userDropDownEndPoint);
    } else {
      const queryParams = {
        role_id: data,
      };
      return this.get(this.userDropDownEndPoint, true, {
        params: this.toHttpParams(queryParams),
      });
    }
  }

  getPhoneCodeDropdown(): Observable<Dropdown[]> {
    return this.get(this.phoneCodeEndpoint, false);
  }

  getExpenseTypeDropdown(): Observable<Dropdown[]> {
    return this.get(this.expenseTypepEndpoint, false);
  }

  getExpenseStatus(): Observable<Dropdown[]> {
    return this.get(this.expenseStatusEndpoint, false);
  }

  getPolicyFrequency(): Observable<Dropdown[]> {
    return this.get(this.policyFrequencyDropDownEndPoint, false);
  }

  getAccountingPackage(): Observable<Dropdown[]> {
    return this.get(this.accountPackageEndpoint, false);
  }

  getCardDetails(){
    return this.get(this.CardDetailEndPoint, false);
  }

  getPlanDetails() {
    return this.get(this.subscriptionEndpoint);
  }

  getUserListForUserWOKit(id: number) {
    const queryParams = {
      organization_id: id,
    };
    return this.get(this.userWithoutKitEndPoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }

  //#endregion

  //#region PUT
  postOrganizationProcess(status) {
    return this.post(this.organizationProgressEndpoint, status);
  }
  updateOrganizationProcess(status, id) {
    return this.put(this.organizationProgressEndpoint + id + '/', status);
  }

  getUserNotification() {
    return this.get(this.userNotification);
  }

  markAllNotification(data) {
    return this.post(this.userNotification, data);
  }
  //#endregion

  //#region
  showLoader() {
    this.loaderCounter++;
    if (this.loaderCounter === 1) {
      this.updateData('show');
    }
  }

  hideLoader() {
    this.loaderCounter--;
    if (this.loaderCounter === 0) {
      this.updateData('hide');
    }
  }

  clearLoader() {
    this.loaderCounter = 0;
    this.updateData('hide');
  }

  updateData(newData: string) {
    this.dataSubject.next(newData);
  }
  //#endregion
  logout() {
    return this.get(this.logoutEndPoint);
  }

  verify_version(url: string, queryParams) {
    return this.get(url, true, { params: this.toHttpParams(queryParams) });
  }

  resetLoader() {
    return this.get(this.ResetLoader);
  }

  getExportField(id: number) {
    return this.get(this.ExportDataEndPoint + `${id}/`);
  }

  exportData(data) {
    return this.exportCSV(this.ExportDataEndPoint, data, true);
  }

  getKitStatusCnt() {
    return this.get(this.kitStatusCountEndPoint);
  }

  getMainExpenseType(){
    return this.get(this.mainExpenseTypeEndPoint);
  }

  getOrganizationCurrency(){
    return this.get(this.OrganizationCurrencyEndPoint);
  }

  saveOrganizationCurrency(data){
    return this.post(this.OrganizationCurrencyEndPoint,data);
  }

  getExchangeRate(date: any, base: any, symbols: any) {
    const queryParams = {
      date: date,
      base: base,
      symbols: symbols,
    };
    return this.get(this.ExchangeRateEndPoint, true, { params: this.toHttpParams(queryParams) })
  }

  triggerPurchasePlanEvent(data) {
    this.purchasePlanEmitter.emit(data);
  }

  setPinForUser(data: any) {
    return this.post(this.setPinEndPoint,data)
  }

  validatePinForUser(data){
    return this.post(this.validatePinEndPoint,data)
  }

  checkPinStatus() {
    return this.get(this.PinStatusEndPoint)
  }
  resetPinRequest() {
    return this.get(this.resetPinRequestEndPoint)
  }

  resetPinVerification(data){
    return this.post(this.resetPinVerificationEndPoint,data)
  }

  getDefaultUser(value){
    const query_params = {
      data: value
    }
    return this.get(this.userSelectionEndpoint, false, {
      params: this.toHttpParams(query_params)
    })
  }

  checkForClassificationExpense() {
    return this.get(this.expenseClassificationEndpoint)
  }

  classifyExpense(data) {
    return this.put(this.expenseClassificationEndpoint+`${data.id}/`,data)
  }

  getTaxTypeList() {
    return this.get(this.taxTypeEndpoint);
  }

  saveTaxType(value: any) {
    if(value.id ==0 || value.id == null){
      return this.post(this.taxTypeEndpoint,value);
    }else{
      return this.put(this.taxTypeEndpoint + `${value.id}/`,value);
    }
  }

  deleteTaxType(id){
    return this.delete(this.taxTypeEndpoint+`${id}/`);
  }


}
