
export class InputField {
    id:number;
    name:string;
    place_holder:string;
    label:string;
    model:string;
    type:string;
    value:any;
    validator:InputValidations;
    className:string;
    disabled:boolean;
    onChange:string;
    options:any;
    valid:boolean;
    meta:any
    pk:string;

    constructor(){
        this.id=0;
        this.name='';
        this.place_holder='';
        this.label='';
        this.model='';
        this.type='';
        this.value=null
        this.validator=  new InputValidations();
        this.className='';
        this.disabled=false;
        this.onChange='';
        this.options=[]
        this.valid=true;
        this.meta={}

    }
}

export class InputValidations {
    required:boolean;
    min:number;
    max:number;
    decimal_places:number;

    constructor(){
        this.required =false;
        this.min =1;
        this.max =10000000;
        this.decimal_places =2
    }
}

export class TemplateControl{
    main_index:number;
    control: InputField[];
    valid:boolean;
    expense_type_id:number;
    main_expense_type: string;
    pk:string;
    row_number:number;
}

export class BasicFieldData{
pk:string='';
id:number=0;
valid:boolean=false;
value:any=null;

constructor(){
    this.pk = '';
    this.id = null;
    this.valid = false;
    this.value = null;
}
}