<div class="row p-3" *ngIf="advanceTemplate && advanceTemplate.control">
  <div *ngFor="let item of advanceTemplate.control" class="col-sm-12 col-md-4">
    <app-input-autocomplete-place
      *ngIf="item.type == 'autocomplete'"
      [fieldOption]="item"
      (fieldChange)="handleFieldChange($event)"
    ></app-input-autocomplete-place>
    <app-input-number
      *ngIf="item.type == 'number'"
      [fieldOption]="item"
      [pk]="advanceTemplate.pk"
      (valueChanges)="($event)"
      (fieldChange)="handleFieldChange($event)"
    ></app-input-number>

    <app-input-text
      *ngIf="item.type == 'text'"
      [fieldOption]="item"
      (fieldChange)="handleFieldChange($event)"
      [pk]="advanceTemplate.pk"
    ></app-input-text>
    <app-input-calendar
      *ngIf="item.type == 'date'"
      [fieldOption]="item"
      (fieldChange)="handleFieldChange($event)"
      [pk]="advanceTemplate.pk"
    >
    </app-input-calendar>
    <app-input-dropdown
      *ngIf="item.type == 'dropdown'"
      [fieldOption]="item"
      (fieldChange)="handleFieldChange($event)"
      [dropdownList]="options"
      [pk]="advanceTemplate.pk"
    ></app-input-dropdown>
    <app-input-chips
      *ngIf="item.type == 'chip'"
      [fieldOption]="item"
      (fieldChange)="handleFieldChange($event)"
      [pk]="advanceTemplate.pk"
    ></app-input-chips>
  </div>
</div>
