import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelReaderService {
  constructor() { }

  public async readExcel(file: File): Promise<any[]> {
    return await new Promise<any[]>((resolve, reject) => {
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const data: ArrayBuffer = e.target.result;
        const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array' });
        const sheetName: string = workbook.SheetNames[0];
        const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
        const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers: any[] = jsonData.shift();

        const result: any[] = jsonData.map((row: any[]) => {
          const rowData: any = {};
          row.forEach((cell: any, i: number) => {
            rowData[headers[i]] = cell;
          });
          return rowData;
        });
        
        resolve(result);
      };

      reader.onerror = (error: any) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }
}
