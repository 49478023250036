import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/core/base/base.component';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent extends BaseComponent {

  //#region 
  @Input() data :any;
  //#endregion
  constructor() {
    super();
   }

  ngOnInit(): void {
  }

}
