import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'expensetype',
})

export class ExpenseTypePipe implements PipeTransform{

    transform(source: number) {
        if (!source){
            return undefined;
        }

        let expenseType = ""
        if(source === 1){
            expenseType="Suspense";
        }else if(source === 2){
            expenseType="General";
        }else if(source === 3){
            expenseType = "System";
        }else if(source === 4){
            expenseType = "Accommodation";
        }else if(source === 5){
            expenseType = "Mileage";
        }else if(source === 6){
            expenseType = "Time";
        }else if(source === 7){
            expenseType = "Other";
        }

        return expenseType;
    }
}