import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonService } from 'app/core/services/common.service';

@Component({
  selector: 'app-validate-pin',
  templateUrl: './validate-pin.component.html',
  styleUrls: ['./validate-pin.component.scss'],
})
export class ValidatePinComponent {
  @ViewChild('otpInput') otpInputField: any;
  @Output() pin_status = new EventEmitter<string>();
  @Input() mobile:string ='';
  pin: string;
  message: any;
  reset_pin: boolean = true;
  config: any = {
    length: 4,
    allowNumbersOnly: true,
    isPasswordInput: true,
    inputClass: 'otp-num-custom',
  };
  constructor(private cmService: CommonService) {}

  onOtpChange(event) {
    this.pin = event;
    if (this.message != null && this.pin.length != 4){
      if (this.pin.length == 1){
        this.message = null;
      }
    }
    if(this.pin.length == 4 && this.reset_pin){
      this.checkPin();
    }
  }

  cancelPin() {
    this.pin_status.emit('cancel');
  }

  checkPin() {
    this.message = null;
    if (this.pin && this.pin.length == 4) {
      let data = {
        upi_pin: this.pin,
      };
      this.cmService.validatePinForUser(data).subscribe((d) => {
        if (d.status) {
          this.message = d;
          this.pin_status.emit('valid');
          this.otpInputField.setValue(null);
          this.message = null
        } else {
          this.message = d;
          this.otpInputField.setValue(null);
        }
      });
    }
  }

  resetPin() {
    this.message = null;
    this.cmService.resetPinRequest().subscribe((d) => {
      if (d.status) {
        this.otpInputField.setValue(null);
        this.config = {
          length: 4,
          allowNumbersOnly: true,
          isPasswordInput: false,
          inputClass: 'otp-num-custom',
        };
        this.reset_pin = !this.reset_pin;
      }
    });
  }

  verifyOTP() {
    this.message = null;
    if (this.pin && this.pin.length == 4) {
      let data = {
        otp: this.pin,
      };
      this.cmService.resetPinVerification(data).subscribe(resp=>{
        if(resp.status){
          this.pin_status.emit('pin-reset');
          this.otpInputField.setValue(null);
          this.reset_pin = !this.reset_pin;

        }else{
          this.message = resp;
          this.otpInputField.setValue(null);
        }
      });

    }
  }
}
