import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reportpipe',
})
export class ReportStatusPipe implements PipeTransform {
  transform(source:string) {
    if (!source) {
      return undefined;
    }

    let statusName="";
    if(source.toLocaleLowerCase() == "p"){
      statusName="Not Submitted";
    }else if(source.toLocaleLowerCase() == "s"){
      statusName="Submitted for Approval";
    }else if(source.toLocaleLowerCase() =="v"){
      statusName="Verified";
    }else if(source.toLocaleLowerCase() =="r"){
      statusName="Rejected";
    }else if(source.toLocaleLowerCase() =="a"){
      statusName="Awaiting Verification";
    }
    return statusName;

  }
}
// https://stackblitz.com/edit/angular-nevhxm-so?file=src%2Fapp%2Fapp.component.html

// <div *ngFor='let result of results'>
// 	<div [ngClass]='{"myclass": someArray | inArray:result.id }'>
//     {{result.name}}
// 	</div>
// </div>
