import { Component, EventEmitter, Input, Output, NgZone } from '@angular/core';
import { BasicFieldData, InputField } from 'app/core/model/common/input-field.model';

@Component({
  selector: 'app-input-autocomplete-place',
  templateUrl: './input-autocomplete-place.component.html',
  styleUrls: ['./input-autocomplete-place.component.scss'],
})
export class InputAutocompletePlaceComponent {
  valueOfData: string = '';
  @Input() fieldOption: InputField = new InputField();
  @Input() pk: string;
  @Output() fieldChange = new EventEmitter<InputField>(); // Output event
  @Output() valueChanges = new EventEmitter<BasicFieldData>(); // Output event

  requiredError: boolean = false;
  requiredMin: boolean = false;
  requiredMax: boolean = false;

  autocomplete: google.maps.places.Autocomplete;
  latitude: number;
  longitude: number;
  predictions = [];

  constructor(private ngZone: NgZone) {
    this.valueOfData = this.fieldOption.value;
  }

  ngOnInit() {
    this.valueOfData = this.fieldOption.value;
    this.fieldOption.pk = this.pk
  }

  ngAfterViewInit() {
    this.initAutocomplete();
  }

  onInputChange() {
    this.fieldOption.value = this.valueOfData;
    let valid = true;
    this.fieldOption.meta = {};

    if (this.latitude && this.longitude) {
      this.fieldOption.meta.latitude = this.latitude;
      this.fieldOption.meta.longitude = this.latitude;
    } else {
      valid = false;
    }

    this.fieldOption.valid = valid;
    this.fieldChange.emit(this.fieldOption);
  }

  private initAutocomplete() {
    let idName = 'autocompleteInput_' + this.fieldOption.label;
    const autocompleteInput = document.getElementById(
      idName
    ) as HTMLInputElement;
    this.autocomplete = new google.maps.places.Autocomplete(autocompleteInput);

    this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = this.autocomplete.getPlace();
        if (!place.geometry) {
          return;
        }
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.valueOfData = place.formatted_address;
        this.onInputChange()
      });
    });
  }
}
