import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppDataService } from 'app/modules/authentication/services/appdata.service';
import { AuthenticationService } from 'app/modules/authentication/services/authentication.service';
import { LoggedUser } from '../../model/users/_module';
import { UserService } from 'app/modules/user/service/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  connectedUser:LoggedUser;
  currentRole: string;
  initals:string;
  employee_id: number;
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private appData: AppDataService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.connectedUser=this.appData.connectedUser
    this.currentRole=this.appData.currentUserRole
    this.initals = this.connectedUser.first_name.charAt(0).toUpperCase() ;
    this.getEmployeeId()
  }

  logout() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    localStorage.clear();

    this.authService.logout().subscribe(
      (x)=>{
        document.cookie.split(';').forEach(function (c) {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });
        this.router.navigate(['/login']);

      },error=>{
        document.cookie.split(';').forEach(function (c) {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });
        this.router.navigate(['/login']);
      }
    );
  }

  getEmployeeId(){
    this.userService.getUserData(this.connectedUser.user_id).subscribe((x)=>{this.employee_id = x.employee_id})
  }
}
