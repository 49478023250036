<div class="form-group">
  <label for="fieldOption.label" class="form-label">
    {{ fieldOption.name }}
    <span *ngIf="fieldOption.validator[0].required">*</span>
  </label>
  <p-calendar
    [style]="{ width: '100%' }"
    [(ngModel)]="valueOfData"
    [inputId]="fieldOption.label"
    [required]="fieldOption.validator.required"
    [showIcon]="true"
    dateFormat="dd-mm-yy"
    [showIcon]="true"
    appendTo="body"
    [showClear]="true"
    [readonlyInput]="true"
      (onSelect)="onInputChange()"
  ></p-calendar>
</div>
