<div class="container-fluid">
  <div class="row px-4">
    <div
      class="col-sm-12 col-md-4"
      *ngFor="let data of planList; let odd = odd"
      [ngClass]="{ 'border-side': odd }"
    >
      <div class="pricing-plan">
        <span class="badge" [ngClass]="data.id | cssClass : 'label'">{{
          data.name
        }}</span>
        <div class="plan-details">
          <div *ngIf="data.price != 0" class="plan-price">
            {{ data.price 
            }}<span class="plan-duration">/month</span>
          </div>
          <div *ngFor="let d of data.des">{{d}}</div>
          
          <ul class="feature-list">
            <li *ngFor="let detail of data.features">{{detail}}</li>
            
          </ul>
          <div class="payment-btn">
            <button
              type="button"
              *ngIf="data.id == 1 && currentuser.organization.subscription_type_id==1"
              [ngClass]="data.id | cssClass : 'button'"
              (click)="openPlanPurchase(data.id)"
              [disabled]="data.id < currentuser.organization.subscription_type_id"
            >
              Continue
            </button>
            <button
              type="button"
              *ngIf="data.id > currentuser.organization.subscription_type_id"
              [ngClass]="data.id | cssClass : 'button'"
              (click)="openPlanPurchase(data.id)"
              [disabled]="data.id < currentuser.organization.subscription_type_id"
            >
              Buy
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-sm-12 col-md-4 border-side">
        <div class="pricing-plan">
          <span class="badge badge-paid-trial">Paid Trial</span>
          <div class="plan-details">
            <div class="plan-price">$25<span class="plan-duration">/month</span></div>
            <ul class="feature-list">
              <li>List item 1</li>
              <li>List item 2</li>
              <li>List item 3</li>
              <li>List item 4</li>
              <li>List item 5</li>
            </ul>
          </div>
          <div class="payment-btn">
            <button type="button" class="paid-button" (click)="openPlanPurchase('paid')">Buy Now</button>
          </div>
        </div>
      </div>
  
      <div class="col-sm-12 col-md-4">
        <div class="pricing-plan">
          <span class="badge badge-premium">Premium</span>
          <div class="plan-details">
            <div class="plan-price">$20<span class="plan-duration">/month</span></div>
            <ul class="feature-list">
              <li>List item 1</li>
              <li>List item 2</li>
              <li>List item 3</li>
              <li>List item 4</li>
              <li>List item 5</li>
            </ul>
          </div>
          <div class="payment-btn">
            <button type="button" class="premimum-button" (click)="openPlanPurchase('premimum')">Buy Now</button>
          </div>
        </div>
      </div> -->
  </div>
</div>
