import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @Input() userNotification :any;
  @Output() isRead = new EventEmitter<boolean>();
  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    if(this.userNotification){
      this.userNotification.forEach(element => {
        if(!element.title.search("Money Request")){
          element.icon = "icon-cash"
        }else{
          element.icon = "icon-message"
          element.body = element.body.replace(" A.", " Approved.");
          element.body = element.body.replace(" V.", " Verified.");
        }
      });
    }
  }

  ngAfterViewInit(){
    if(this.userNotification){
      let data={"mark_all_read":"true"};
      this.commonService.markAllNotification(data).subscribe(x=>{
        this.isRead.emit(true);
      })
    }

  }

}
