// http-interceptor.service.ts

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EncryptionService } from '../services/encrypt.service';

@Injectable({
  providedIn: 'root',
})
export class EncodeInterceptor implements HttpInterceptor {
  constructor(private encryptionService: EncryptionService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request and encode query parameters
    const encryptedRequest = this.encryptQueryParameters(request);

    // Pass the cloned request to the next handler
    return next.handle(encryptedRequest);
  }

  private encryptQueryParameters(
    request: HttpRequest<any>
  ): HttpRequest<any> {
    // Encrypt query parameters here
    let clonedParams = new HttpParams();
    
    request.params.keys().forEach((key) => {
      const encryptedValue = this.encryptionService.encrypt(
        request.params.get(key)
      );
      clonedParams = clonedParams.set(key, encryptedValue);
    });

    // Clone the request and set the encrypted query parameters
    const clonedRequest = request.clone({
      params: clonedParams,
    });

    return clonedRequest;
  }
  
}
