
<!-- 5Days free trial-->
<div class="row g-0 h-100 svg-container" *ngIf="item == '25'" >

      <div class="col-6 m-auto">
        <img src="../../../../assets/img/day/5.svg" height="405" alt="reminder" class="image-center img img-fluid" />
      </div>
      <div class="col-6 m-auto bg-white">
        <div class="close-btn-dialog">
          <i class="pi pi-times fs-4 fs-normal text-body-tertiary" (click)="closeReminder()"></i>
        </div>
        <div class="content-box-info pt-5">
          <h4 class="text-center">
            Unlock the Full Potential of Expense Management!
          </h4>
          <p class="five-day">
            Congrats on diving into our Expense Management app 🎉
            <br />
            Ready to level up your financial game?<br />Unlock Premium features,
            exclusive insights, and seamless expense control with our premium
            plan.<br />
            Elevate your financial journey starting today!
          </p>

          <button class="btn btn-danger text-center w-upgrade" (click)="upgradePlan('FREE')">
            Upgrade Now
          </button>
          <h6 class="text-center my-3">OR</h6>
          <p class="fs-6 mb-5">
            Looking for 50+ Users? <a href="mailto:sales@bizpay.co.in">Contact Sales</a>
          </p>
        </div>
      </div>

</div>

<!-- 15Days free trial-->
<div class="row g-0 h-100 svg-container" *ngIf="item == '15'" >

      <div class="col-6 m-auto">
        <img src="../../../../assets/img/day/15.svg" height="405" alt="reminder"  class="img-fluid"/>
      </div>
      <div class="col-6 m-auto bg-white">
        <div class="close-btn-dialog">
          <i class="pi pi-times fs-4 fs-normal text-body-tertiary" (click)="closeReminder()"></i>
        </div>
        <div class="content-box-info pt-1 pb-0 px-3">
          <h4 class="text-center px-5 pt-4">
            Gateway to Excellence: Unveiling Premium Benefits Beyond the Door
          </h4>

            <table class="tbl w-100" >
              <tr class="text-center">
                <td class="free-trial w-50">Free Trial</td>
                <td class="premium-plan border-start border-white">
                  Premium Plan
                </td>
              </tr>
              <tr class="text-start mb-1" *ngFor="let item of free_trial; let i = index">
                <td>
                  <div class="row bg-transparent">
                    <div class="col-1 mt-1">
                      <img src="../../../../assets/img/day/success.svg" height="20" alt="reminder" />
                    </div>
                    <div class="col-11 mt-1">
                      <span class="ps-0 f-size">{{ item.text }}</span>
                    </div>
                  </div>
                </td>
                <td class="border-start border-white">
                  <div class="row bg-transparent">
                    <div class="col-1 mt-1">
                      <img src="../../../../assets/img/day/success.svg" height="20" alt="reminder" />
                    </div>
                    <div class="col-11 mt-1">
                      <span class="ps-0 f-size">{{ premium_plan[i].text }}</span>
                    </div>
                  </div>
                </td>
              </tr>
              <ng-container *ngFor="
        let item of premium_plan.slice(
          0,
          showMoreItems ? premium_plan.length : initialItemCount
        );
        let i = index
      ">
                <ng-container *ngIf="i >= free_trial.length">
                  <tr class="text-start">
                    <td></td>
                    <td class="border-start border-white">
                      <div class="row bg-transparent">
                        <div class="col-1 mt-1">
                          <img src="../../../../assets/img/day/success.svg" height="20" alt="reminder" />
                        </div>
                        <div class="col-11 mt-1">
                          <span class="ps-0 f-size">{{ item.text }}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <tr *ngIf="!showMoreItems && premium_plan.length > initialItemCount">
                <td></td>
                <td>
                  <p class="pointer text-primary" (click)="showMoreItems = true">
                    Show More
                  </p>
                </td>
              </tr>
            </table>

          <button class="btn btn-danger text-center w-upgrade mt-4" (click)="upgradePlan('FREE')">
            Upgrade Now
          </button>
          <h6 class="text-center my-3">OR</h6>
          <p class="fs-6 mb-5">
            Looking for 50+ Users? <a href="mailto:sales@bizpay.co.in">Contact Sales</a>
          </p>
        </div>
      </div>

</div>

<!-- 25Days free trial -->
<div class="row g-0 h-100 svg-container" *ngIf="item == '5'" >

      <div class="col-6 m-auto">
        <img src="../../../../assets/img/day/25.svg" height="405" alt="reminder" class="img-fluid image-center"/>
      </div>
      <div class="col-6 m-auto bg-white">
        <div class="close-btn-dialog">
          <i class="pi pi-times fs-4 fs-normal text-body-tertiary" (click)="closeReminder()"></i>
        </div>
        <div class="content-box-info">
          <h4 class="text-center">
            Last Call: Elevate your Expense Management before Time Runs Out!
          </h4>
          <p class="twenty-five-day">
            Only <span class="days-span">5 days</span> left to transition to our
            Premium Plan for uninterrupted access to powerful expense management
            tools. Upgrade now and take control of your finances
          </p>
          <button class="btn btn-danger text-center w-upgrade" (click)="upgradePlan('FREE')">
            Upgrade Now
          </button>
          <h6 class="text-center my-3">OR</h6>
          <p class="fs-6 mb-5">
            Looking for 50+ Users? <a href="mailto:sales@bizpay.co.in">Contact Sales</a>
          </p>
        </div>
      </div>

</div>

<!-- 15Days Premium Plan -->
<div class="row g-0 h-100 svg-container" *ngIf="item == '15P'" >

      <div class="col-6 m-auto">
        <img src="../../../../assets/img/day/25p.svg" height="405" alt="reminder" class="img-fluid image-center"/>
      </div>
      <div class="col-6 m-auto bg-white">
        <div class="close-btn-dialog">
          <i class="pi pi-times fs-4 fs-normal text-body-tertiary" (click)="closeReminder()"></i>
        </div>
        <div class="content-box-info mt-5">
          <h4 class="text-center">
            Don't Let the Growth of your Savings Stop!
          </h4>
          <p class="twenty-five-day">
            Only <span class="days-span">15 days</span> left for your
            subscription to expire! To ensure continual flow of transactions,
            reports and benefits in your business,
            <br />
            Renew your subscription today!
          </p>

          <button class="btn btn-danger text-center w-upgrade mb-5" (click)="upgradePlan('PREMIUM')">
            Renew Now
          </button>
        </div>
      </div>

</div>

<!-- 5Days Premium Plan -->
<div class="row g-0 h-100 svg-container" *ngIf="item == '5P'" >
 
      <div class="col-6 m-auto">
        <img src="../../../../assets/img/day/15p.svg" height="405" alt="reminder"  class="img-fluid image-center"/>
      </div>
      <div class="col-6 m-auto bg-white">
        <div class="close-btn-dialog">
          <i class="pi pi-times fs-4 fs-normal text-body-tertiary" (click)="closeReminder()"></i>
        </div>
        <div class="content-box-info mt-4">
          <h4 class="text-center">
            Continue to be on Top of your Business Spending!
          </h4>
          <p class="twenty-five-day">
            Stay at the top of your expense management game with BizPay. Only
            <span class="days-span">5 days</span> left for your subscription to
            expire. Renew Now for uninterrupted flow of financial activities in
            your business
          </p>

          <button class="btn btn-danger text-center mb-5 w-upgrade" (click)="upgradePlan('PREMIUM')">
            Renew Now
          </button>
        </div>
      </div>

</div>


