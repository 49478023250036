import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-free-trial-reminder',
  templateUrl: './free-trial-reminder.component.html',
  styleUrls: ['./free-trial-reminder.component.scss']
})
export class FreeTrialReminderComponent implements OnInit {

  free_trial: any[];
  premium_plan: any[];
  trial_periods: any[];
  @Input() item: string = '';
  @Input() height: number = 0;
  @Output() upgradeType = new EventEmitter<string>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  showMoreItems: boolean = false;
  initialItemCount: number = 5;
  constructor(){
    this.trial_periods = [
      { key: "5" },
      { key: "5P" },
      { key: "15" },
      { key: "25" },
      { key: "15P" },
    ];
  }

  ngOnInit(){
    this.free_trial = [
      { text: 'Upto 5 users only for 30 days'}, {text: "Automated Expense Reporting"}, {text: "Simplified & Efficient Reimbursement Process"}
    ];
    this.premium_plan = [
      { text: "Unlimited Users"},
      { text: "Automated Expense Reporting"},
      { text: "Simplified & Efficient Reimbursement Process"},
      { text: "Dedicated Account Manager to help you set up your account & get the maximum value out of BizPay"},
      { text: "Assign UPI Enabled Wallets & BizPay Corporate Cards to employees"},
      { text: "Automated Expense Capture & Classification"},
      { text: "Automated Advance to Settlement workflow"},
      { text: "Expense Bucket Analysis with Real time Budget Vs. Actual comparisons"},
      { text: "Integration with Accounting, ERP & HRMS systems"},
      { text: "Integrate BizPay with over 6000 SaaS applications with Zapier"},
    ];
    // this.item = this.trial_periods[4].key;
  }

  upgradePlan(type: string){
    this.upgradeType.emit(type);
  }

  closeReminder(){
    this.close.emit();
  }
}
