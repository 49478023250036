export class SignUp {
  public firstName: string;
  public lastName: string;
  public email: string;
  public mobile: number;
  public countryCode: number;
  public organizationName: string;
  public industry: string;
  public website: string;
  public password: string;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.mobile = null;
    this.countryCode = null;
    this.organizationName = '';
    this.industry = null;
    this.website = '';
    this.password = '';
  }
}
