import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CacheClearService {
  constructor(private router: Router) {
    this.clearCacheOnLoad();
  }

   clearCacheOnLoad(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'imperative' || event.navigationTrigger === 'popstate') {
          // Clear any relevant caches or perform cache-related actions here
          // For example, you can clear the browser's localStorage or sessionStorage
          localStorage.clear();
          sessionStorage.clear();
          console.log("Cache Cleared!");
        }
      }
    });
  }
}
