import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment'; // Import Moment.js

@Pipe({
  name: 'unixToDateTime'
})
export class UnixToDateTimePipe implements PipeTransform {


  transform(value: string, format = 'YYYY-MM-DD'): string {
    if (value.length !== 14) {  // Check for expected length (14 digits)
      return 'Invalid input';  // Handle invalid input
    }

    const date = moment(value, 'YYYYMMDDHHmmss');  // Custom format for parsing

    if (date.isValid()) {
      return date.format(format);  // Format if valid
    } else {
      return 'Invalid date';  // Handle invalid date string
    }
  }
}
