import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Dropdown } from 'app/core/model/common/dropdown.model';
import { CommonService } from 'app/core/services/common.service';
import { Observable } from 'rxjs';
import moment from 'moment';
import saveAs from 'file-saver';
import { Constant } from 'app/core/common/constant';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss'],
})
export class ExportDataComponent implements OnInit {
  @Input() report_type: string = '';
  id: number = 0;

  fields: any[]; // Update with your user model
  userList$: Observable<Dropdown[]>;
  form: FormGroup;
  today = new Date();
  start_date: any;
  end_date: any;
  file_name: any;

  toggled: boolean = true;
  minToDate: Date;
  maxFromDate: Date;
  startDateError: boolean = false;
  endDateError: boolean = false;

  constructor(private fb: FormBuilder, private commonService: CommonService) {}

  ngOnInit() {
    this.form = this.fb.group({
      // Form controls...
    });
    this.start_date = new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate()-30
    );
    this.minToDate = this.start_date
    this.end_date = this.today;
    this.maxFromDate = this.end_date
    if (this.report_type == 'expense') {
      this.id = 1;
      this.file_name=this.report_type 
    }else if(this.report_type == 'moneyrequest'){
      this.id = 2;
      this.file_name=this.report_type 
    }else if (this.report_type == 'report'){
      this.id = 3;
      this.file_name=this.report_type 
    }else if(this.report_type == 'transaction'){
      this.id = 4;
      this.file_name=this.report_type 
    }
    this.loadUsers();
    this.loadField();
  }

  createForms() {
    const formControls = {};
    for (const item of this.fields) {
      formControls[item.value] = [item.status];
    }
    this.form = this.fb.group(formControls);
  }
  loadUsers() {
    this.userList$ = this.commonService.getUserDropdown();
  }

  loadField() {
    this.commonService.getExportField(this.id).subscribe((res) => {
      this.fields = res;
      this.createForms();
      this.commonService.resetLoader()
    });
  }

  resetFilter() {
    const formControls = {};
    for (const item of this.fields) {
      formControls[item.key] = true;
    }
    this.form = this.fb.group(formControls);
    this.start_date = new Date();
    this.end_date = new Date();
  }

  onSubmit(): void {
    this.fields.forEach((item) => {
      const formControlName = item.value;
      item.status = this.form.get(formControlName).value;
    });
    let str_date = moment(this.start_date);
    let en_date = moment(this.end_date);

    const diffInDays = en_date.diff(str_date, 'days');

    var element = {
      id: this.id,
      start_date: str_date.format('YYYY-MM-DD'),
      end_date: en_date.format('YYYY-MM-DD'),
      fields: this.fields,
    };

    if(diffInDays <= 30 && diffInDays >= 0){
      this.commonService.exportData(element).subscribe((response) => {
        const blob = new Blob([response], { type: 'text/csv' });
        const filename = this.file_name +".csv";
        saveAs(blob, filename);      
      });
    }
  }
  
  onFromDateChange(event) {
    this.minToDate = event;
    if(this.start_date > this.today){
      this.startDateError = true;
    }else{
      this.startDateError = false;
      if (this.start_date && this.end_date) {
        const str_date = moment(this.start_date); 
        const en_date = moment(this.end_date);
    
        const diffInDays = en_date.diff(str_date, 'days'); 
    
        if (diffInDays > 30) {
          this.end_date = str_date.clone().add(30, 'days').toDate();
        }else{
          this.end_date = this.end_date;
        }
      }
      this.updateMaxFromDate();
      if(this.end_date <= this.today){
        this.endDateError = false;
      }
    }
  }

  onToDateChange(event) {
    this.maxFromDate = event;
    if(this.end_date > this.today){
      this.endDateError = true;
    }else{
      this.endDateError = false;
      if (this.start_date && this.end_date) {
      const str_date = moment(this.start_date); 
      const en_date = moment(this.end_date);

      const diffInDays = en_date.diff(str_date, 'days'); 

      if (diffInDays > 30) {
        this.start_date = en_date.clone().subtract(30, 'days').toDate();
      }
    }
    this.updateMinToDate();
    }
  }

  updateMinToDate() {
    if (this.start_date) {
      this.minToDate = moment(this.start_date).toDate();
    }
  }

  updateMaxFromDate() {
    if (this.end_date) {
      this.maxFromDate = moment(this.end_date).toDate();
    }
  }
}
