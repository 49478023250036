<div class="row body-container" *ngIf="selected_data">
  <div class="col-12 text-center my-3">
    <img src="../../../../assets/img/congratulation.svg" alt="congratulation" />
  </div>
  <div class="col-12 text-center">
    <h2 class="payment-success">Payment Successful!</h2>
    <h4 class="payment-message">
      You have successfully paid to <strong style="font-weight: 500;">{{merchant_name}}</strong><br/>
      <strong *ngIf="upi_id != ''" style="font-weight: 500;">({{upi_id}})</strong>
    </h4>

    <h1 class="payment-amount my-4">
      {{selected_data.amount  | currency
        : currency_data.code
        : "symbol"
        : currency_data.digits
        : currency_data.locale}}
         <img alt="check" src="../../../../assets/img/green-check.svg" />
    </h1>

    <h4 class="payment-option">
      Select one of the following options to classify the above transaction
    </h4>
    <div class="my-4">
      <button class="btn btn-outline-secondary mx-3 w-25" type="button" (click)="onClassify(2)">
        To Self
      </button>
      <button class="btn btn-primary w-25" type="button" (click)="onClassify(0)">For Expense</button>
      <button class="visually-hidden" pAutoFocus [autofocus]="true"></button>
    </div>
    <h4 class="payment-note">
      <strong>Note:</strong> ‘To Self’ will increase your cash balance & ‘For Expense’ will
      create an automatic expense
    </h4>
  </div>
  <div class="col-12 mt-5 text-center" *ngIf="expenseList.length!=1">
    <p-button
    class="mx-2"
      icon="pi pi-chevron-left"
      (click)="onPrevious()"
    />
    <p-button
      icon="pi pi-chevron-right"
      (click)="onNext()"
    />
  </div>
</div>
