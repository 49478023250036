//#region imports
import { Injectable } from '@angular/core';
import { Routes, Route } from '@angular/router';
//#endregion imports

//#region internal imports
import { AuthGuardService } from '../guard/auth.guard';
import { DefaultLayoutComponent } from '../layout/_module';
import { AdminLayoutComponent } from '../layout/_module';
import { AdminAuthGuardService } from '../guard/admin-auth.guard';

//#endregion internal imports

@Injectable({
  providedIn: 'root'
})
export class AdminShell {

   /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: AdminLayoutComponent,
      children: routes,
      canActivate: [AdminAuthGuardService,],
      // Reuse Component instance when navigating between child views
      data: { reuse: true }
    };
  }
}
