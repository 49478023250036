// css-class.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cssClass',
})
export class SubscriptionClassPipe implements PipeTransform {
  transform(subscription_id: number, type: string): string {
    // Define the CSS class mappings using an object.
    const cssClassMappings: { [key: string]: { [key: number]: string } } = {
      label: {
        1: 'badge-new',
        2: 'badge-paid-trial',
        3: 'badge-premium',
      },
      button: {
        1: 'continue-button',
        2: 'paid-button',
        3: 'premimum-button',
      },
    };

    // Check if the subscription_id and type are valid in the mappings.
    const isValidSubscriptionId = subscription_id >= 1 && subscription_id <= 3;
    const isValidType = cssClassMappings.hasOwnProperty(type);

    if (isValidSubscriptionId && isValidType) {
      return cssClassMappings[type][subscription_id];
    } else {
      return '';
    }
  }
}
